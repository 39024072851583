import styles from "./styles/baseballPreloader.module.scss";
export default function BaseballPreloader({ containerStyle, imageStyle }) {
  return (
    <div className={styles.baseballPreloader} style={containerStyle}>
      <img
        style={imageStyle}
        src="/svg/baseball.svg"
        alt="Baseball Preloader"
      />
    </div>
  );
}
