import { useState, useEffect } from "react";

import Cart from "../../classes/Cart";
import { CostumeItem } from "../../classes/CartItem";

import CostumeList from "./components/CostumeList";
import styles from "./index.module.scss";
import Preview from "./components/Preview";
import { Link } from "react-router-dom";

export default function CostumeProduct({ product }) {
  const cart = new Cart();
  const [isAdd, setIsAdd] = useState(false);
  const [transitionOptions, setTransitionOptions] = useState([
    { name: "RING", id: 1 },
    { name: "FADE", id: 2 },
  ]);

  const default_options = product.default_options;
  const [woodId, setWoodId] = useState(default_options.wood);
  const [barrelId, setBarrelId] = useState(default_options.barrel);
  const [barrelFinishId, setBarrelFinishId] = useState(
    default_options.barrel_finish
  );
  const [gripId, setGripId] = useState(default_options.grip);
  const [gripFinishId, setGripFinishId] = useState(default_options.grip_finish);
  const [ringId, setRingId] = useState(default_options.ring);
  const [logoId, setLogoId] = useState(default_options.logo);
  const [sizeId, setSizeId] = useState(default_options.size);
  const [weightId, setWeightId] = useState(default_options.weight);
  const [cupId, setCupId] = useState(default_options.cup);
  const [engraving, setEngraving] = useState("");
  const [engravingCount, setEngravingCount] = useState(25);
  const [quantity, setQuantity] = useState(1);
  const [price, setPrice] = useState(null);

  const woodHandler = (id) => setWoodId(id);
  const barrelHandler = (id) => setBarrelId(id);
  const barrelFinishHandler = (id) => setBarrelFinishId(id);
  const gripHandler = (id) => setGripId(id);
  const gripFinishHandler = (id) => setGripFinishId(id);
  const ringHandler = (id) => setRingId(id);
  const logoHandler = (id) => setLogoId(id);
  const sizeHandler = (id) => setSizeId(id);
  const weightHandler = (id) => setWeightId(id);
  const cupHandler = (id) => setCupId(id);

  const engravingHandler = (evt) => {
    const len = evt.target.value.trim().length;
    const max = 25;
    if (len <= max) {
      setEngraving(evt.target.value);
      setEngravingCount(max - len);
    }
  };

  const changeQty = (num) => {
    const newQty = quantity + num;
    if (newQty < 1) {
      setQuantity(1);
    } else {
      setQuantity(quantity + num);
    }
  };

  const getTotal = () => {
    let total = quantity * price;
    return total.toFixed(2);
  };

  const handlerAddToCart = () => {
    const cartItem = new CostumeItem(
      product.id,
      quantity,
      woodId,
      barrelId,
      barrelFinishId,
      gripId,
      gripFinishId,
      ringId,
      logoId,
      sizeId,
      weightId,
      cupId,
      engraving
    );

    cart.addItem(cartItem);
    setIsAdd(true);
  };

  const gradientHandler = () => {
    if (ringId) {
      setRingId(null);
    } else {
      setRingId(default_options.ring);
    }
  };

  useEffect(() => {
    if (barrelId === gripId) {
      setTransitionOptions([{ name: "RING", id: 1 }]);
    } else {
      setTransitionOptions([
        { name: "RING", id: 1 },
        { name: "FADE", id: 2 },
      ]);
    }
  }, [gripId, barrelId]);

  useEffect(() => {
    if (barrelFinishId !== gripFinishId) {
      setRingId(default_options.ring);
      setTransitionOptions([]);
    } else {
      setTransitionOptions([
        { name: "RING", id: 1 },
        { name: "FADE", id: 2 },
      ]);
    }
  }, [gripFinishId, barrelFinishId, default_options.ring]);

  useEffect(() => {
    const woodPrices = product.costume_options?.woodTypes;
    if (woodPrices && woodPrices.count) {
      const defaultPrice = woodPrices.items.find((wood) => wood.id === woodId);
      if (defaultPrice) setPrice(defaultPrice.price.price);
    }
  }, [woodId, product.costume_options?.woodTypes]);

  return (
    <>
      <Preview
        model={product.model}
        options={product.costume_options}
        engraving={engraving}
        woodId={woodId}
        barrelId={barrelId}
        gripId={gripId}
        ringId={ringId}
        logoId={logoId}
        sizeId={sizeId}
        weightId={weightId}
        barrelFinishId={barrelFinishId}
        gripFinishId={gripFinishId}
      />

      <div className="center">
        <h1>{product.title}</h1>

        <section id={styles.costumeDetails}>
          <article id={styles.information}>
            <article>{product.description}</article>
          </article>

          <section className={styles.options}>
            <section className={styles.textureOptions}>
              {product.costume_options.woodTypes && (
                <>
                  <CostumeList
                    title="Wood Species"
                    type="box"
                    styleOpt="box"
                    list={product.costume_options.woodTypes}
                    handler={woodHandler}
                    current={woodId}
                  />
                </>
              )}

              {product.costume_options.barrelColors && (
                <>
                  <h2>Barrel</h2>
                  <div className={styles.twoColumns}>
                    <CostumeList
                      // title="Barrel"
                      type="color"
                      list={product.costume_options.barrelColors}
                      handler={barrelHandler}
                      current={barrelId}
                    />
                    {product.costume_options.barrelFinished && (
                      <CostumeList
                        type="box"
                        styleOpt="boxFixed"
                        list={product.costume_options.barrelFinished}
                        handler={barrelFinishHandler}
                        current={barrelFinishId}
                      />
                    )}
                  </div>
                </>
              )}

              {product.costume_options.gripColors && (
                <>
                  <h2>Handle</h2>
                  <div className={styles.twoColumns}>
                    <CostumeList
                      // title="Handle"
                      type="color"
                      list={product.costume_options.gripColors}
                      handler={gripHandler}
                      current={gripId}
                    />
                    {product.costume_options.gripFinished && (
                      <CostumeList
                        type="box"
                        styleOpt="boxFixed"
                        list={product.costume_options.gripFinished}
                        handler={gripFinishHandler}
                        current={gripFinishId}
                      />
                    )}
                  </div>
                </>
              )}

              {product.costume_options.ringColors && (
                <>
                  <h2>Transition</h2>
                  <div className={styles.twoColumns}>
                    <CostumeList
                      // title="Ring"
                      list={product.costume_options.ringColors}
                      type="color"
                      handler={ringHandler}
                      current={ringId}
                    />
                    <CostumeList
                      type="box"
                      styleOpt="boxFixed"
                      list={{
                        count: 1,
                        items: transitionOptions,
                      }}
                      handler={gradientHandler}
                      current={ringId ? 1 : 2}
                    />
                  </div>
                </>
              )}

              {product.costume_options.logoColors && (
                <CostumeList
                  title="Logo"
                  list={product.costume_options.logoColors}
                  type="image"
                  handler={logoHandler}
                  current={logoId}
                />
              )}

              {product.costume_options.sizes && (
                <CostumeList
                  title="Sizes"
                  list={product.costume_options.sizes}
                  type="box"
                  styleOpt="boxFixedList"
                  handler={sizeHandler}
                  current={sizeId}
                />
              )}

              {Boolean(product.costume_options.weights) &&
                Boolean(product.costume_options.weights.count) && (
                  <CostumeList
                    title="Weights"
                    list={product.costume_options.weights}
                    type="box"
                    styleOpt="boxFixedList"
                    handler={weightHandler}
                    current={weightId}
                  />
                )}

              {Boolean(product.costume_options.cups) &&
                Boolean(product.costume_options.cups.count) && (
                  <CostumeList
                    title="Cups"
                    list={product.costume_options.cups}
                    type="box"
                    styleOpt="boxFixedList"
                    handler={cupHandler}
                    current={cupId}
                  />
                )}
            </section>

            <section className={styles.cartOptions}>
              <article>
                <h2>Engraving</h2>
                <div className="field">
                  <input
                    type="text"
                    value={engraving}
                    onChange={engravingHandler}
                  />
                  <span className={`${styles.note}`}>
                    * {engravingCount} characters.{" "}
                  </span>
                </div>
              </article>
              <article>
                <h2>TOTAL</h2>
                <div className={`${styles.total}`}>${getTotal()} USD</div>
                {!isAdd ? (
                  <div className={styles.addToCart}>
                    <div className={styles.qty}>
                      <div className={styles.counter}>
                        <span onClick={(evt) => changeQty(-1)}>▼</span>
                        <span>{quantity}</span>
                        <span onClick={(evt) => changeQty(1)}>▲</span>
                      </div>
                    </div>
                    <button
                      onClick={handlerAddToCart}
                      className={`good ${styles.btn}`}
                    >
                      add to cart
                    </button>
                  </div>
                ) : (
                  <div className={`${styles.goToCheckout}`}>
                    <Link to="/checkout">GO TO CHECKOUT</Link>
                  </div>
                )}
              </article>
            </section>
          </section>
        </section>
      </div>
    </>
  );
}
