import { useState } from "react";
import { useCountries } from "hooks/useCountries";

import styles from "./buyerInformation.module.scss";
import BaseballPreloader from "components/BaseballPreloader";

export default function BuyerInformation({
  isEditable,
  isShippingLoading,
  globalError,
  setIsEditable,
  setBuyer,
  buyer,
}) {
  const [name, setName] = useState(buyer.name);
  const [email, setEmail] = useState(buyer.email);
  const [phone, setPhone] = useState(buyer.phone);
  const [country, setCountry] = useState(buyer.country);
  const [state, setState] = useState(buyer.state);
  const [city, setCity] = useState(buyer.city);
  const [zipCode, setZipCode] = useState(buyer.zipCode);
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");

  const { loading: loadingCountries, data: countriesList } = useCountries();

  const setError = (ele, msg) => {
    const parent = ele.parentElement;
    const span = parent.querySelector("span");
    span.innerHTML = msg;
    span.classList.add(styles.active);
  };

  const cleanErrors = () => {
    const errorElements = document.querySelectorAll(".field span");
    errorElements.forEach((e) => e.classList.remove(styles.active));
  };

  const handlerEditButton = (e) => {
    e.preventDefault();
    setIsEditable(true);
  };

  const joinAddresses = (value) => {
    return address1 + " " + address2;
  };

  const handlerForm = async (e) => {
    e.preventDefault();
    buyer.name = name;
    buyer.email = email;
    buyer.phone = phone;
    buyer.country = country;
    buyer.state = state;
    buyer.city = city;
    buyer.zipCode = zipCode;
    buyer.address = joinAddresses();

    try {
      cleanErrors();
      buyer.checkData();
      setIsEditable(false);
      setBuyer(buyer);
    } catch (err) {
      setError(err.element, err.errmsg);
    }
  };

  return (
    <div className={styles.form}>
      <h2>Contact Information</h2>
      <form>
        <div className={`field ${styles.error}`}>
          <label htmlFor="fname">Name:</label>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            id="fname"
            disabled={!isEditable}
          />
          <span></span>
        </div>
        <div className={styles.columns}>
          <div className={`field ${styles.error}`}>
            <label htmlFor="femail">Email:</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              id="femail"
              disabled={!isEditable}
            />
            <span></span>
          </div>
          <div className={`field ${styles.error}`}>
            <label htmlFor="fphone">Phone Number:</label>
            <input
              type="tel"
              onChange={(e) => setPhone(e.target.value)}
              value={phone}
              id="fphone"
              disabled={!isEditable}
            />
            <span></span>
          </div>
        </div>
        <div className={styles.columns}>
          <div className="field">
            <label htmlFor="fcountry">Country:</label>
            <select
              disabled={!isEditable}
              id="fcountry"
              onChange={(e) => setCountry(e.target.value)}
            >
              {loadingCountries && <option value="null">Loading...</option>}
              {!loadingCountries &&
                countriesList.map((country) => (
                  <option key={country.iso} value={country.iso}>
                    {country.name}
                  </option>
                ))}
            </select>
          </div>
          <div className={`field ${styles.error}`}>
            <label htmlFor="fstate">State:</label>
            <input
              type="text"
              onChange={(e) => setState(e.target.value)}
              value={state}
              id="fstate"
              disabled={!isEditable}
            />
            <span></span>
          </div>
        </div>
        <div className={styles.columns}>
          <div className={`field ${styles.error}`}>
            <label htmlFor="fcity">City:</label>
            <input
              type="text"
              onChange={(e) => setCity(e.target.value)}
              value={city}
              id="fcity"
              disabled={!isEditable}
            />
            <span></span>
          </div>
          <div className={`field ${styles.error}`}>
            <label htmlFor="fzip">Zip Code:</label>
            <input
              type="text"
              id="fzip"
              onChange={(e) => setZipCode(e.target.value)}
              value={zipCode}
              disabled={!isEditable}
            />
            <span></span>
          </div>
        </div>
        <div className={`field ${styles.error}`}>
          <label htmlFor="faddress">Address Line 1 (Calle y numero):</label>
          <input
            type="text"
            id="faddress"
            onChange={(e) => setAddress1(e.target.value)}
            value={address1}
            disabled={!isEditable}
          />
          <span></span>
        </div>
        <div className={`field ${styles.error}`}>
          <label htmlFor="faddress">
            Address Line 2 (Fraccionamiento o Colonia):
          </label>
          <input
            type="text"
            id="faddress2"
            onChange={(e) => setAddress2(e.target.value)}
            value={address2}
            disabled={!isEditable}
          />
          <span></span>
        </div>
        <div
          className={`${styles.globalError} ${
            globalError ? styles.active : ""
          }`}
        >
          {globalError}
        </div>
        <div className={styles.btn}>
          {isShippingLoading && (
            <BaseballPreloader imageStyle={{ width: "25px" }} />
          )}

          {!isShippingLoading && isEditable && (
            <button className="good" onClick={handlerForm}>
              Continue
            </button>
          )}

          {!isShippingLoading && !isEditable && (
            <button className="good" onClick={handlerEditButton}>
              Edit
            </button>
          )}
        </div>
      </form>
    </div>
  );
}
