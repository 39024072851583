export default class Cart {
  _emptyCart = { count: 0, products: [] };
  count = 0;
  products = [];

  constructor() {
    const cart = localStorage.getItem("cart");
    if (cart) {
      const toObject = JSON.parse(cart);
      this.count = toObject.count;
      this.products = toObject.products;
    } else {
      this.count = this._emptyCart.count;
      this.products = this._emptyCart.products;
    }
    this._saveCart();
  }

  getCart = () => {
    return {
      count: this.count,
      products: this.products,
    };
  };

  getQuantityOfItems() {
    return this.products.reduce((sum, product) => sum + product.quantity, 0);
  }

  /**
   * Add and item to the cart
   * @param {CartItem} cartItem
   */
  addItem(cartItem) {
    this.count++;
    this.products.push(cartItem.get());
    this._saveCart();
  }

  removeItem(index) {
    this.products.splice(index, 1);
    this.count--;
    this._saveCart();
  }

  cleanCart = () => {
    localStorage.setItem("cart", JSON.stringify(this._emptyCart));
  };

  exist = (id) => {
    return this.find(id) ? true : false;
  };

  find = (id) => {
    return this.products.find((product) => product.productId === id);
  };

  _saveCart() {
    localStorage.setItem(
      "cart",
      JSON.stringify({
        count: this.count,
        products: this.products,
      })
    );
  }
}
