import styles from "./index.module.scss";

export default function TermsAndConditions() {
  return (
    <main id={styles.terminos}>
      <div className={`center ${styles.center}`}>
        <h1>TERMINOS Y CONDICIONES</h1>

        <article>
          <h2>INFORMACIÓN GENERAL</h2>
          <p>
            Victory Vbats Co. toma muy en serio su privacidad. Lea la siguiente
            información para obtener más información sobre nuestras prácticas de
            privacidad.
          </p>

          <p>
            Esta política cubre qué información de identificación personal
            recopila Victory VBats Co. y cómo se trata. La información de
            identificación personal es información que se puede usar para
            identificar a los visitantes del sitio web http://www.vbats.com.
            Esta información puede limitarse a una dirección IP o extenderse a
            información de identificación personal, como su nombre, dirección,
            dirección de correo electrónico o número de teléfono.
          </p>

          <p>
            Esta política solo se aplica a{" "}
            <a href="http://www.vbats.com">http://www.vbats.com</a> y no se
            extiende a las prácticas de otros sitios web a los que podemos
            vincularnos, a las empresas que no poseemos o controlamos, ni a las
            personas que no empleamos o administramos.
          </p>
        </article>

        <article>
          <h2>INFORMACIÓN DEL CONTACTO</h2>

          <p>
            Le invitamos a contactarnos si tiene preguntas sobre esta política
            de privacidad. Nuestra dirección de Internet se encuentra en
            http://www.vbats.com
          </p>

          <div className="datos">
            Victory VBats Co.
            <br />
            Calle 13 #400, Guadalupe Victoria.
            <br />
            C.P. 21720
            <br />
            Mexicali, Baja California, Mexico
            <br />
            Puede contactarnos por correo electrónico a{" "}
            <a href="mailto:victorybats@gmail.com">
              victorybats@gmail.com
            </a>{" "}
            <br />
            Puede contactarnos por teléfono al{" "}
            <a href="tel:6585163416">(658) 516.3416</a> <br />
          </div>
        </article>

        <article>
          <h2>RESOLUCIÓN DE CONFLICTOS</h2>

          <p>
            VBats Co. se compromete a resolver cualquier disputa que pueda tener
            con respecto a nuestra política de privacidad de manera rápida y
            eficiente.
          </p>
          <p>
            Las disputas serán manejadas por nuestro representante de servicio
            al cliente. Nuestro servicio al cliente corregirá rápidamente
            cualquier error.
          </p>
        </article>
      </div>
    </main>
  );
}
