import React from "react";

import styles from "./styles/PreloadingCard.module.scss";

export default function PreloadingCard() {
  return (
    <div className={styles.preloadingCard}>
      <figure></figure>
      <p></p>
    </div>
  );
}
