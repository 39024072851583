import React from "react";
import styles from "../styles/colorOption.module.scss";

// const Texture
const Color = ({ hex, name, type }) => {
  return (
    <div className={styles.colorBox} style={{ backgroundColor: hex }}>
      <span style={{ borderColor: hex }}>{name}</span>
    </div>
  );
};

const Texture = ({ uri, name }) => {
  return (
    <div
      className={styles.colorBox}
      style={{ backgroundImage: "url(" + uri + ")" }}
    >
      <span>{name}</span>
    </div>
  );
};

export default function ColorIcon({ color, handler, current }) {
  const isActive = current === color.id;

  return (
    <figure
      className={`${styles.colorIcon} ${isActive ? styles.active : ""} `}
      onClick={() => handler(color.id)}
    >
      {isActive && (
        <div className={styles.check}>
          <img src="/svg/check.svg" alt="Selected" />
        </div>
      )}

      {!color.hex ? (
        <>
          <Texture name={color.name} uri={color.images.thumbnail} />
        </>
      ) : (
        <Color hex={color.hex} name={color.name} />
      )}
    </figure>
  );
}
