class CartItem {
  _product_id = null;
  _quantity = 0;
  _production_time = 1;

  constructor(productId, quantity, productionTime) {
    this._product_id = productId;
    this._quantity = quantity;
    this._production_time = productionTime;
  }

  getProductId() {
    return this._product_id;
  }

  get() {
    return {
      productId: this._product_id,
      quantity: this._quantity,
      productionTime: this._production_time,
    };
  }
}

export class CostumeItem extends CartItem {
  _details = {
    barrel_id: null,
    barrel_finish_id: null,
    grip_id: null,
    grip_finish_id: null,
    ring_id: null,
    logo_id: null,
    size_id: null,
    weight_id: null,
    cup_id: null,
    engraving: "",
  };

  constructor(
    productId,
    quantity,
    woodId,
    barrelId,
    barrelFinishId,
    gripId,
    gripFinishId,
    ringId,
    logoId,
    sizeId,
    weightId,
    cupId,
    engraving,
    productionTime
  ) {
    super(productId, quantity, productionTime);

    this._details.wood_id = woodId;
    this._details.barrel_id = barrelId;
    this._details.barrel_finish_id = barrelFinishId;
    this._details.grip_id = gripId;
    this._details.grip_finish_id = gripFinishId;
    this._details.ring_id = ringId;
    this._details.logo_id = logoId;
    this._details.weight_id = weightId;
    this._details.size_id = sizeId;
    this._details.cup_id = cupId;
    this._details.engraving = engraving;
  }

  get() {
    const parent = super.get();
    parent.details = this._details;
    return parent;
  }
}
