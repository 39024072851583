import React from "react";
import { Link } from "react-router-dom";

import styles from "./styles/Card.module.scss";

export default function Card({ item }) {
  const truncateText = (text, maxLength = 128) => {
    if (text.length > maxLength) {
      return `${text.substring(0, maxLength)}...`;
    }
    return text;
  };

  return (
    <div className={styles.item}>
      <figure>
        <Link to={`/product/${item.id}/${item.model}`}>
          {item.thumbnail ? (
            <img src={`${item.thumbnail}`} alt={`${encodeURI(item.model)}`} />
          ) : (
            <img
              src="/svg/thumbnail-placeholder.svg"
              alt={`${encodeURI(item.model)}`}
            />
          )}
        </Link>
        <h3>{`${item.title}`}</h3>
      </figure>
      <p>{`${truncateText(item.description)}`}</p>
    </div>
  );
}
