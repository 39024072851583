const _url = process.env.REACT_APP_API_URL;

export const getApiData = async (url = "") => {
  try {
    const req = await fetch(_url + url);
    const res = await req.json();
    return res;
  } catch (e) {
    console.error(e);
  }
};

export const postApiData = async (url = "", data) => {
  try {
    const req = await fetch(_url + url, {
      method: "POST",
      body: JSON.stringify(data),
    });
    const res = await req.json();
    return res;
  } catch (e) {
    console.error(e);
  }
};
