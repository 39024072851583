// import styles from "./index.module.scss";

export default function CostumeProduct({ product }) {
  // const isInCart = false;
  // console.log(product);

  return <div></div>;

  // return (
  //   <>
  //     <section id={styles.stockDetails}>
  //       <figure>
  //         <img src={product.thumbnail} alt={product.title} />
  //       </figure>
  //       <article>
  //         <h1>{product.title}</h1>

  //         <div className={styles.mainDetails}>
  //           <div className={styles.detail}>
  //             <h3>Modelo:</h3>
  //             <p>{product.model}</p>
  //           </div>
  //           <div className={styles.price}>
  //             ${`${product.price.total.toFixed(2)} ${product.price.currency}`}
  //           </div>
  //         </div>

  //         <div className={styles.description}>{product.description}</div>

  //         <div className={styles.details}>
  //           {product.details.map((detail) => {
  //             return (
  //               <div className={styles.detail} key={detail.id}>
  //                 <h3>{detail.name}: </h3>
  //                 <p>{detail.value}</p>
  //               </div>
  //             );
  //           })}
  //         </div>

  //         {!isInCart ? (
  //           <div className={styles.addToCart}>
  //             <div className={styles.qty}>
  //               <div>Cantidad:</div>
  //               <div className={styles.counter}>
  //                 <span>▲</span>
  //                 <span>1</span>
  //                 <span>▼</span>
  //               </div>
  //             </div>
  //             <div className={styles.btn}>Agregar al carrito</div>
  //           </div>
  //         ) : (
  //           <div className={styles.removeFromCart}>
  //             <div className={styles.btn}>Remover del carrito</div>
  //           </div>
  //         )}
  //       </article>
  //     </section>

  //     <section>
  //       <h2>MAS PRODUCTOS</h2>

  //       {/* <ProductList loading={loading} data={data} /> */}
  //     </section>
  //   </>
  // );
}
