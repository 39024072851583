import { useRandom } from "hooks/useProducts";
import ProductList from "components/products/ProductList";
import styles from "./index.module.scss";

export default function Index() {
	const { data, loading, error } = useRandom();

	return (
		<main id={styles.index}>
			<video autoPlay muted loop>
				<source src="/videos/Version corregida_120 FPS.mp4" type="video/mp4" />
			</video>

			{!error && (
				<div className="center" id={styles.random}>
					<h2>PRODUCTOS DESTACADOS</h2>
					<ProductList loading={loading} data={data} />
				</div>
			)}

			<figure
				id={styles.paralax}
				style={{ backgroundImage: `url('/images/index/paralax01.jpg')` }}
			></figure>

			<section id={styles.quienesSomos}>
				<h2 className="center">QUIENES SOMOS</h2>

				<article className={`center ${styles.aboutInfo}`}>
					<figure>
						<img src="/images/quienes-somos.jpg" alt="Quienes Somos" />
					</figure>
					<article>
						<h3>DE GPE VICTORIA KM 43.</h3>
						<p>
							<span>Victory Vbats Co</span>, Una empresa 100% mexicana que nace
							en el año 2011, en la Ciudad de Mexicali, B.C. México.
						</p>
						<p>
							Impulsados por el amor a este bello deporte y un gran compromiso
							con la calidad y satisfacción de nuestros clientes, en poco tiempo
							hemos logrado posicionarnos como una de las mejores marcas de Bats
							en todos los niveles en el beisbol Mexicano, Sur de Estados Unidos
							y Latinoamérica.
						</p>
						<p>
							Integrados por un equipo de Ingenieros y excelentes Artesanos,
							<span>Vbats Co</span>. Se compromete con el diseño y fabricación
							de bats de Calidad Superior que se ajusten a las necesidades
							específicas de cada bateador.
						</p>
					</article>
				</article>

				<article className={`center ${styles.aboutInfo}`}>
					<article>
						<h3>¿PORQUE UTILIZAR VBATS CO?</h3>

						<p>
							Producimos Bats con madera de Calidad Superior, tales como Rock
							Maple, Yellow Birch y Northern White Ash, no importa el nivel que
							juegues, la calidad siempre será la misma.
						</p>
						<p>
							Contamos con Ingenieros y artesanos capacitados y sobretodo
							comprometidos con el diseño y fabricación de bats que proporcionen
							gran balance y poder, además de un gran acabado.
						</p>
						<p>
							Fabricamos modelos a medida, es decir, si en nuestro catálogo no
							encuentra el modelo que necesite, podemos diseñar el bate que se
							ajuste a su swing.
						</p>
						<p>
							¡¡¡Por eso y mucho más, <span>VBats</span> es la mejor opción!!!
						</p>
					</article>
					<figure>
						<img
							src="/images/quality-bats.jpg"
							alt="By Kenny Nguyễn Unsplash"
						/>
					</figure>
				</article>

				<article className={`center ${styles.aboutInfo}`}>
					<figure>
						<img src="/images/wood-types.jpg" alt="Quienes Somos" />
					</figure>
					<article>
						<h3>WOOD SPECIES</h3>

						<p>
							<span>Hard Maple:</span>Es una de las especies más ideales para la
							elaboración de los bats de béisbol y sin duda, una de las especies
							favoritas por la mayoría de los peloteros profesionales. Esto
							debido a su alta densidad y dureza.
						</p>
						<p>
							<span>Yellow Birch:</span>Se ha convertido en una madera muy
							popular recientemente, es una excelente alternativa al Maple o Ash
							respecto al rendimiento. Esta madera es más fibrosa, pareciera una
							combinación entre Maple y Ash, una de las particularidades de esta
							madera es que es más liviana que el maple, lo cual nos ayuda a
							fabricar bats de barril grande con un peso muy ligero.
						</p>

						<p>
							<span>Northern White Ash:</span> Durante más de un siglo, el Ash
							ha sido una de las especies más populares para la elaboración de
							bats de béisbol. El Ash tiene como características, flexibilidad,
							es ligero y su disponibilidad es muy buena.
						</p>
					</article>
				</article>
			</section>

			<section id={styles.contacto} className="center">
				<h2 className="center">COMUNICATE CON NOSOTROS</h2>
				<div>
					<form>
						<div className={styles.field}>
							<label htmlFor="fName">NOMBRE: *</label>
							<input type="text" id="fName" />
						</div>
						<div className={styles.field}>
							<label htmlFor="fPhone">TELÉFONO:</label>
							<input type="tel" />
						</div>
						<div className={styles.field}>
							<label htmlFor="fEmail">CORREO ELECTRÓNICO: *</label>
							<input type="email" />
						</div>
						<div className={styles.field}>
							<label htmlFor="fMensaje">MENSAJE: *</label>
							<textarea></textarea>
						</div>
						<div className={styles.btn}>
							<button className={styles.good} type="submit">
								ENVIAR
							</button>
						</div>
					</form>
					<article>
						<div className={styles.dato}>
							<span>Direccion: </span> Gpe. Victoria Km 43 Mexicali, B.C. México
						</div>
						<div className={styles.dato}>
							<span>Teléfono:</span>
							<a href="tel:6861638588" rel="noopener noreferrer">
								{" "}
								(686) 163.8588
							</a>
						</div>
						<div className={styles.dato}>
							<span>Email:</span>
							<a href="mailto:victorybats@gmail.com" rel="noopener noreferrer">
								victorybats@gmail.com
							</a>
						</div>
						<div className={styles.dato}>
							<span>Redes Sociales:</span>
							<div className={styles.redesSociales}>
								<a
									href="https://www.facebook.com/Victory-Bats-191025177649737/"
									target="_blank"
									rel="noopener noreferrer"
								>
									<img src="/svg/facebook-logo.svg" alt="Facebook" />
								</a>
								<a
									href="https://twitter.com/VictoryBats"
									target="_blank"
									rel="noopener noreferrer"
								>
									<img src="/svg/twitter-logo.svg" alt="Twitter" />
								</a>
								<a
									href="https://www.instagram.com/vbatsco/"
									target="_blank"
									rel="noopener noreferrer"
								>
									<img src="/svg/instagram-logo.svg" alt="Instagram" />
								</a>
							</div>
						</div>
					</article>
				</div>
			</section>
		</main>
	);
}
