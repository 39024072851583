import { Link } from "react-router-dom";
import styles from "./styles/breadcrumbs.module.scss";

const makeLink = (item) => {
  return {
    id: item.id,
    name: item.name,
    url: `/products/${item.id}/${decodeURIComponent(item.name)}`,
  };
};

export default function Breadcrumbs({ data }) {
  let links = [{ id: 0, name: "home", url: "/" }];

  data.supercategories.items.forEach((cat) => {
    if (cat.supercategories.count > 0) {
      cat.supercategories.items.forEach((item) => {
        links.push(makeLink(item));
      });
    }
    links.push(makeLink(cat));
  });

  links.push(makeLink(data));

  return (
    <div className={`center ${styles.breadcrumbs}`}>
      {data &&
        links.map((item) => {
          return (
            <span key={item.id}>
              <Link to={item.url}> {item.name}</Link> /
            </span>
          );
        })}
    </div>
  );
}
