import styles from "./styles/ProductList.module.scss";

import PreloadingList from "components/products/PreloadingList";
import Card from "./Card";

export default function ProductList({ data, loading }) {
  return (
    <div className={styles.productList}>
      {/* When loading!! */}
      {loading && <PreloadingList />}
      {/* When response items is empty  */}
      {data && !data.items.length && (
        <div className={styles.emptyList}>
          Soon we will include products in this section.
        </div>
      )}
      {/* When response have items */}
      {data &&
        data.items.length !== 0 &&
        data.items.map((item, index) => <Card key={index} item={item} />)}
    </div>
  );
}
