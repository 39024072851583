import ImageOption from "./ImageOption";
import BoxOption from "./BoxOption";
import ColorOption from "./ColorOption";
import styles from "../styles/lists.module.scss";

const getBox = (item, current, handler) => {
  const name = item.name ? item.name : `${item.value} ${item.measurement}`;
  const icon = item.icon ? item.icon : null;
  const recommended = item.recommended ? item.recommended : null;
  const price = item.price ? item.price : null;

  return (
    <BoxOption
      key={item.id}
      name={name}
      icon={icon}
      id={item.id}
      price={price}
      handler={handler}
      current={current}
      recommended={recommended}
    />
  );
};

const getColor = (item, current, handler) => {
  return (
    <ColorOption
      key={item.id}
      color={item}
      handler={handler}
      current={current}
    />
  );
};

const getImage = (item, current, handler) => {
  return (
    <ImageOption
      key={item.id}
      color={item}
      handler={handler}
      current={current}
    />
  );
};

// Types = {box || color || image}
// Styles = {box || boxFixed}
export default function BoxOptionList({
  title,
  list,
  type,
  styleOpt,
  handler,
  current,
}) {
  const cls = styleOpt ? styles[styleOpt] : "";
  return (
    <article>
      {title && <h2>{title}</h2>}

      <div className={`${styles.list} ${cls}`}>
        {list.count &&
          list.items.map((item) => {
            if (type === "box") return getBox(item, current, handler);
            if (type === "color") return getColor(item, current, handler);
            if (type === "image") return getImage(item, current, handler);
            return null;
          })}
      </div>
    </article>
  );
}
