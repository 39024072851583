import React, { useEffect, useState } from "react";
import styles from "../styles/preview.module.scss";

import MateBarrel from "./Preview/MateBarrel";
import MateGrip from "./Preview/MateGrip";
import GlossBarrel from "./Preview/GlossBarrel";
import GlossGrip from "./Preview/GlossGrip";
import PreviewColor from "./Preview/PreviewColor";
import PreviewEngraving from "./Preview/PreviewEngraving";

export default function Preview({
  model,
  options,
  woodId,
  barrelId,
  gripId,
  ringId,
  logoId,
  sizeId,
  weightId,
  engraving,
  barrelFinishId,
  gripFinishId,
}) {
  const [sticky, setSticky] = useState(false);
  const [logoColor, setLogoColor] = useState("#ffffff");

  ////////////////////////////////
  // Scroll stuff
  ////////////////////////////////
  const handleScroll = (evt) => {
    const offset = window.scrollY;
    if (offset > 200) {
      setSticky(true);
    } else {
      setSticky(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
  }, []);

  return (
    <div
      className={sticky ? `${styles.sticky}` : ""}
      id={styles.previewContainer}
    >
      <figure>
        {/* Base Layer */}
        <img
          src="/images/costume-layers/1.png"
          style={{ opacity: 1 }}
          alt="Base Layer"
        />

        {/* Color Layers */}
        <PreviewColor
          gripId={gripId}
          barrelId={barrelId}
          logoId={logoId}
          ringId={ringId}
          barrelOptions={options.barrelColors}
          gripOptions={options.gripColors}
          logoOptions={options.logoColors}
          ringOptions={options.ringColors}
          setLogoColor={setLogoColor}
        />

        {/* Detail Layer */}
        <img
          src="/images/costume-layers/3-multiplicar.png"
          style={{ opacity: 0.5, mixBlendMode: "multiply" }}
          alt="Wood Multiplier"
        />

        {/* Mate/Gloss Layer */}
        {barrelFinishId === 1 ? <MateBarrel /> : <GlossBarrel />}
        {gripFinishId === 1 ? <MateGrip /> : <GlossGrip />}

        {/* Engraving Layer */}
        <PreviewEngraving
          model={model}
          engraving={engraving}
          sizeId={sizeId}
          weightId={weightId}
          color={logoColor}
          woodId={woodId}
          woodOptions={options.woodTypes}
          weightOptions={options.weights}
          sizeOptions={options.sizes}
        />

        <img src="/images/costume-layers/mascara.png" alt="Costume Bat Mask" />
      </figure>
      <span className={styles.note}>
        NOTA: MEDIDA DE BAT STANDAR SOLO PARA DEMOSTRACIÓN,
        <br />
        LAS CARACTERISTICAS SE ADJUNTAN CON EL BAT SELECCIONADO
      </span>
    </div>
  );
}
