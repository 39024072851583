import { useEffect, useState } from "react";
import { getApiData } from "services/getApiData";

export const useProducts = (catid) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    getApiData("/products/?catid=" + catid).then((data) => {
      if (!data.errno) setData(data);
      else setError(data);
      setLoading(false);
    });
  }, [catid]);

  return { data, loading, error };
};

export const useRandom = (count = 4) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    getApiData("/products/?rand=" + count).then((data) => {
      if (!data.errno) setData(data);
      else setError(data);
      setLoading(false);
    });
  }, [count]);

  return { data, loading, error };
};
