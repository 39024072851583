import { useRef, useEffect } from "react";
import { useParams, useNavigate } from "react-router";
import { Link } from "react-router-dom";

import { useCategory } from "hooks/useCategories";
import { useProducts } from "hooks/useProducts";

import styles from "./index.module.scss";
import ProductList from "components/products/ProductList";
import BreadCrumbs from "components/BreadCrumbs";

export default function Products() {
  const navigate = useNavigate();
  const menuEle = useRef();
  const { cid } = useParams();

  const { data: catData, error: catError } = useCategory(cid);
  const { loading, data, error } = useProducts(cid);

  /**
   * This generete a problem on the redirection
   * TODO: Fix console problem
   */
  if (catError) navigate("/");

  const handleScroll = (evt) => {
    if (!menuEle.current) {
      return;
    }
    const offset = window.scrollY;
    if (offset > 200) {
      menuEle.current.classList.add(styles.sticky);
    } else {
      menuEle.current.classList.remove(styles.sticky);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
  }, []);

  return (
    <main id={styles.productList}>
      <h1>{catData ? catData.name.toUpperCase() : "LOADING..."}</h1>

      {catData && <BreadCrumbs data={catData} />}

      <menu ref={menuEle}>
        {"\u00A0"}
        {catData &&
          Boolean(catData.subcategories.count) &&
          catData.subcategories.items.map((subcategory) => {
            return (
              <li key={subcategory.id}>
                <Link
                  to={`/products/${subcategory.id}/${catData.name}/${subcategory.name}`}
                >
                  {subcategory.name.toUpperCase()}
                </Link>
              </li>
            );
          })}
      </menu>
      <section className={`center ${styles.list}`}>
        {!error && <ProductList loading={loading} data={data ? data : null} />}
      </section>
    </main>
  );
}
