import { useEffect, useState } from "react";

import { getApiData } from "services/getApiData";

const _url = "/product/";

export const findDetailById = (id, detailsList) => {
  return detailsList.find((e) => e.id === id);
};

export const useProduct = (id = 0) => {
  const [product, setProduct] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  // const [error, setError] = useState(null);

  useEffect(() => {
    setIsLoading(true);
    getApiData(_url + "?id=" + id).then((data) => {
      setProduct(data);
      setIsLoading(false);
    });
  }, [id]);

  return { product, isLoading };
};
