import React from "react";
import styles from "../privacy/index.module.scss";

export default function Thanks() {
  return (
    <main id={styles.privacy}>
      <div className={`center ${styles.center}`}>
        <h1>THANKS FOR YOUR PURCHASE</h1>

        <article>
          <p>
            We have received your order satisfactorily and will review it to
            contact you.
          </p>
        </article>
      </div>
    </main>
  );
}
