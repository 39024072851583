export default function NotFound() {
  const styles = {
    container: {
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      justifyContent: "center",
      minHeight: "50vh",
      padding: "50px",
    },
    number: {
      fontWeight: "bold",
      fontSize: "5em",
      marginTop: "-50px",
    },
    notFound: {
      fontWeight: "bold",
      fontSize: "2em",
      marginBottom: "25px",
    },
    link: {
      color: "black",
    },
  };

  return (
    <main>
      <section style={styles.container}>
        <figure style={styles.tupa}>
          <img
            src="//obejanegra.com.mx/imagenes/dead-tupa.png"
            alt="Dead Tupa"
          />
        </figure>
        <div style={styles.number}>404</div>
        <div style={styles.notFound}>PAGE NOT FOUND</div>
        <div>
          <a style={styles.link} href="/">
            Go to home page
          </a>
        </div>
      </section>
    </main>
  );
}
