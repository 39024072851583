import React from "react";
import styles from "../styles/boxOption.module.scss";

export default function BoxOption({
  id,
  name,
  icon,
  price,
  recommended,
  handler,
  current,
  note,
}) {
  const isActive = current === id;

  return (
    <div
      onClick={(evt) => handler(id)}
      className={`
  			${styles.boxOption}
  			${isActive ? styles.active : ""}
  			${icon ? styles.withImage : ""}`}
    >
      {isActive && (
        <div className={styles.check}>
          <img src="/svg/check.svg" alt="Selected" />
        </div>
      )}
      {name.toUpperCase()}
      {price && <span>{`${price.price.toFixed(2)} ${price.currency}`}</span>}
      {note && <span className={styles.note}>{`${note}`}</span>}
      {recommended && " *"}
      {icon ? (
        <figure>
          <img src={icon} alt={`${name} leaf`} />
        </figure>
      ) : null}
    </div>
  );
}
