import { useParams } from "react-router";

import ProductList from "components/products/ProductList";
import CostumeProduct from "./CostumeProduct";
import StockProduct from "./StockProduct";

import styles from "./index.module.scss";
import { useProduct } from "hooks/useProduct";
import { useRandom } from "hooks/useProducts";

export default function Product() {
  const pId = useParams().pid;
  const { product, isLoading } = useProduct(pId);
  const { data, loading } = useRandom(4);

  return (
    <main id={styles.product}>
      {isLoading && <div>Cargando...</div>}

      {/* If is costumizable */}
      {product && product.costume_options && (
        <CostumeProduct product={product} />
      )}

      {/* If not is costumizable */}
      {product && !product.costume_options && (
        <StockProduct product={product} />
      )}
      <div className={`center `}>
        <h2>OTHER POPULAR PRODUCTS</h2>
        <ProductList loading={loading} data={data} />
      </div>
    </main>
  );
}
