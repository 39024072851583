import styles from "../../styles/blendOpacity.module.scss";

export default function GlossBarrel() {
  return (
    <div>
      <img
        src="/images/costume-layers/4-1-gloss-barrel.png"
        className={`${styles.dodge} ${styles.opacity42}`}
        alt="Gloss Layer 1"
      />
      <img
        src="/images/costume-layers/4-2-gloss-barrel.png"
        className={`${styles.dodge} ${styles.opacity42}`}
        alt="Gloss Layer 2"
      />
      <img
        src="/images/costume-layers/4-3-gloss-barrel.png"
        className={`${styles.dodge} ${styles.opacity42}`}
        alt="Gloss Layer 3"
      />
      <img
        src="/images/costume-layers/4-4-gloss-barrel.png"
        className={`${styles.screen} ${styles.opacity35}`}
        alt="Gloss Layer 4"
      />
      <img
        src="/images/costume-layers/4-5-gloss-barrel.png"
        className={`${styles.colorBurn} ${styles.opacity50}`}
        alt="Gloss Layer 5"
      />
    </div>
  );
}
