import React from "react";
import styles from "../styles/imageOption.module.scss";

export default function ImageOption({ color, handler, current }) {
  const isActive = current === color.id;

  return (
    <figure
      className={`${styles.imageIcon} ${isActive ? styles.active : ""} `}
      onClick={() => handler(color.id)}
    >
      {isActive && (
        <div className={styles.check}>
          <img src="/svg/check.svg" alt="Selected" />
        </div>
      )}

      <div>
        <img src={color.images.logo_thumbnail} alt="Thumbnail" />
        <span style={{ borderColor: color.hex }}>{color.name}</span>
      </div>
    </figure>
  );
}
