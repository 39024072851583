import styles from "../../styles/previewEngraving.module.scss";

const getCurrentDate = () => {
  const options = { month: "short" };
  const m = new Intl.DateTimeFormat("en-US", options).format();
  const y = new Date().getFullYear();
  return m + "/" + y;
};

const getSizeWeights = (size, weight) => {
  if (size && weight) {
    return (size + weight).toFixed(2);
  }
};

export default function PreviewEngraving({
  model,
  engraving,
  color,
  woodId,
  sizeId,
  weightId,
  woodOptions,
  sizeOptions,
  weightOptions,
}) {
  const wood = woodOptions.items.find((item) => item.id === woodId);
  const size = sizeOptions.items.find((item) => item.id === sizeId);
  const weight = weightOptions.items.find((item) => item.id === weightId);

  return (
    <span className={`${styles.engraving}`} style={{ color }}>
      <div className={`${styles.mediumText}`}>
        V-BATS CUSTOM {`${model.toUpperCase()}`}
      </div>
      <div className={`${styles.customeEngraving}`}>
        {engraving || "\u00A0"}
      </div>
      <div className={`${styles.smallText}`}>
        {`${getCurrentDate()} - `}
        {size?.value + " " + size?.measurement + " / "}
        {getSizeWeights(size?.value, weight?.value) + " " + weight?.measurement}
      </div>
      <div className={`${styles.mediumText}`}>
        PRO HARDENED {wood.name.toUpperCase()}
      </div>
    </span>
  );
}
