import { useEffect, useState, useRef } from "react";
import { Link, useLocation } from "react-router-dom";

import styles from "./styles/Header.module.scss";

export default function Header() {
  const location = useLocation();
  const [sticky, setSticky] = useState(false);
  const { pathname } = useLocation();
  const drawerEle = useRef(null);

  const handleScroll = (evt) => {
    const offset = window.scrollY;
    if (offset > 200) {
      setSticky(true);
    } else {
      setSticky(false);
    }
  };

  const handlerDrawer = (evt) => {
    drawerEle.current.classList.toggle(styles.active);
  };

  useEffect(() => {
    drawerEle.current.classList.remove(styles.active);
  }, [location]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
  }, []);

  return (
    <header
      className={`${styles.header} ${sticky ? styles.sticky : ""} ${
        pathname === "/" ? styles.onlyIndex : ""
      }`}
    >
      <div className={`${styles.center}`}>
        <div></div>
        <figure>
          <Link to="/">
            <img
              src="/svg/horizontal-main-logo-white.svg"
              alt="Official Logo"
            />
          </Link>
        </figure>
        <div className={styles.icons}>
          <div className={styles.icon}>
            <Link to="/checkout">
              <img src="/svg/cart-white-icon.svg" alt="Cart icon" />
            </Link>
          </div>
          <div className={styles.icon} onClick={handlerDrawer}>
            <img src="/svg/nav-white-icon.svg" alt="Navegation icon" />
          </div>
        </div>
      </div>
      <div ref={drawerEle} className={`${styles.drawerContainer}`}>
        <div onClick={handlerDrawer} className={styles.background}></div>
        <nav>
          <figure>
            <Link to="/">
              <img src="/svg/square-main-logo.svg" alt="Official Logo" />
            </Link>
            <div className={styles.closeBtn} onClick={handlerDrawer}>
              <img src="/svg/light-close-btn.svg" alt="Close Nav Btn" />
            </div>
          </figure>
          <ul>
            <li>
              <Link to="/products/1/costume-bats">CUSTOM BATS</Link>
              <ul>
                <li>
                  <Link to="/products/5/baseball-bats/custome-bats/pro-series-adult">
                    PRO SERIES ADULT
                  </Link>
                </li>
                <ul>
                  <li>
                    <Link to="/products/7/baseball-bats/custome-bats/pro-series-adult/balance">
                      BALANCE
                    </Link>
                  </li>
                  <li>
                    <Link to="/products/8/baseball-bats/custome-bats/pro-series-adult/slightly-end-loaded">
                      SLIGHTLY END LOADED
                    </Link>
                  </li>
                  <li>
                    <Link to="/products/9/baseball-bats/custome-bats/pro-series-adult/end-loaded">
                      END LOADED
                    </Link>
                  </li>
                </ul>
              </ul>
              <ul>
                <li>
                  <Link to="/products/12/baseball-bats/custome-bats/pro-series-youth">
                    PRO SERIES YOUTH
                  </Link>
                </li>
                <li>
                  <Link to="/products/10/baseball-bats/custome-bats/pro-series-youth/training">
                    TRAINING
                  </Link>
                </li>
                <li>
                  <Link to="/products/11/baseball-bats/custome-bats/pro-series-youth/softball">
                    SOFTBALL
                  </Link>
                </li>
              </ul>
            </li>
            {/* <li>
              <Link to="/products/2/baseball-bats/ready-to-ship">
                READY TO SHIP
              </Link>
            </li>
            <li>
              <Link to="/products/3/batting-gloves">BATTING GLOVES</Link>
            </li>
            <li>
              <Link to="/products/3/apparel">APPAREL</Link>
            </li> */}
          </ul>
        </nav>
      </div>
    </header>
  );
}
