const requireIdFields = [
  "fname",
  "femail",
  "fphone",
  "fstate",
  "fcity",
  "fzip",
  "faddress",
];

const errors = {
  require: "* This field is require.",
};

export default class Buyer {
  _name = "";
  _email = "";
  _phone = "";
  _country = "MX";
  _state = "";
  _city = "";
  _zipCode = "";
  _address = "";

  get name() {
    return this._name;
  }

  get email() {
    return this._email.toLowerCase();
  }

  get phone() {
    return this._phone;
  }

  get country() {
    return this._country;
  }

  get state() {
    return this._state;
  }

  get city() {
    return this._city;
  }

  get zipCode() {
    return this._zipCode;
  }

  get address() {
    return this._address;
  }

  set name(value) {
    this._name = value;
  }

  set email(value) {
    this._email = value;
  }

  set phone(value) {
    this._phone = value;
  }

  set country(value) {
    this._country = value;
  }

  set state(value) {
    this._state = value;
  }

  set city(value) {
    this._city = value;
  }

  set zipCode(value) {
    this._zipCode = value;
  }

  set address(value) {
    this._address = value;
  }

  checkData() {
    requireIdFields.forEach((ele) => {
      const field = document.getElementById(ele);
      if (field.value.trim() === "") {
        const error = { errno: 100, errmsg: errors.require, element: field };
        throw error;
      }
    });
  }

  getShippingInformation() {
    return {
      city: this.city,
      country: this.country,
      zipcode: this.zipCode,
    };
  }
}
