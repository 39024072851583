import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import ScrollToTop from "./router/ScrollToTop";

//Routes
import Index from "./pages/index";
import TermsAndConditions from "./pages/terminosYCondiciones";
import Privacy from "./pages/privacy";
import Products from "./pages/products";
import Checkout from "./pages/checkout";
import Product from "./pages/product";
import Thanks from "./pages/thanks";
import NotFound from "./pages/404";

function App() {
  return (
    <>
      <BrowserRouter>
        <Header />
        <ScrollToTop>
          <Routes>
            <Route path="/" element={<Index />} />
            <Route
              path="/terms-and-conditions"
              element={<TermsAndConditions />}
            />
            <Route path="/privacy-policy" element={<Privacy />} />
            <Route path="/checkout" element={<Checkout />} />
            <Route path="/products/:cid/*" element={<Products />} />
            <Route path="/product/:pid/*" element={<Product />} />
            <Route path="/thanks" element={<Thanks />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </ScrollToTop>
        <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;
