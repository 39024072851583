import styles from "../../styles/blendOpacity.module.scss";

export default function MateGrip() {
  return (
    <div>
      <img
        src="/images/costume-layers/4-1-mate-grip.png"
        className={`${styles.screen} ${styles.opacity42}`}
        alt="Mate Layer 1"
      />
      <img
        src="/images/costume-layers/4-2-mate-grip.png"
        className={`${styles.colorBurn}`}
        alt="Mate Layer 2"
      />
    </div>
  );
}
