export class CheckoutItem {
  id = null;
  title = "";
  model = "";
  quantity = 0;
  price = null;
  production_time = 1;
  product = null;

  constructor(quantity, production_time, product) {
    this.product = product;
    this.quantity = quantity;
    this.production_time = production_time;

    this.id = product.id;
    this.title = product.title;
    this.model = product.model;
    this.price = this.getPrice();
  }

  getPrice() {
    if (!this.price) {
      const woods = this.product.costume_options.woodTypes;
      const wood = woods.items.find(
        (item) => item.id === this.product.selected_options.wood_id
      );
      this.price = wood.price;
    }
    return this.price;
  }

  getSubtotal() {
    return this.quantity * this.getPrice().price;
  }

  getTotal() {
    const subtotal = this.getSubtotal();
    return {
      total: subtotal,
      currency: this.getPrice().currency,
    };
  }

  getProductionType() {
    return this.production_time === 1 ? "Standard" : "Rush";
  }
}
