import PreloadingCard from "components/products/PreloadingCard";

export default function PreloadingList({ qty = 4 }) {
  const loadingElements = [...Array(qty)];

  return (
    <>
      {loadingElements.map((item, index) => (
        <PreloadingCard key={index} />
      ))}
    </>
  );
}
