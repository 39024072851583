import { useEffect, useState } from "react";
// import styles from "../../styles/blendOpacity.module.scss";

export default function PreviewColor({
  gripId,
  barrelId,
  logoId,
  ringId,
  gripOptions,
  barrelOptions,
  ringOptions,
  logoOptions,
  setLogoColor,
}) {
  const [isGradient, setIsGradient] = useState(false);
  const [barrelColor, setBarrelColor] = useState(null);
  const [gripColor, setGripColor] = useState(null);

  const blendStyles = {
    gray: {
      "mix-blend-mode": "color-burn",
    },
    pink: {
      "mix-blend-mode": "hue",
    },
    washedWhite: {
      "mix-blend-mode": "color-dodge",
    },
    flame: {
      "mix-blend-mode": "darken",
    },
    green: {
      "mix-blend-mode": "hard-light",
    },
    default: {
      "mix-blend-mode": "multiply",
    },
  };

  const barrel = barrelOptions.items.find((item) => item.id === barrelId);
  const grip = gripOptions.items.find((item) => item.id === gripId);
  const ring = ringOptions.items.find((item) => item.id === ringId);
  const logo = logoOptions.items.find((item) => item.id === logoId);

  let barrelStyle = null;
  let gripStyle = null;

  useEffect(() => {
    if (barrelId === gripId) {
      setBarrelColor(barrel?.images?.barrel);
      setGripColor(grip?.images?.grip);
    } else {
      if (isGradient) {
        setBarrelColor(barrel?.images?.barrel_gradient);
        setGripColor(grip?.images?.grip_gradient);
      } else {
        setBarrelColor(barrel?.images?.barrel);
        setGripColor(grip?.images?.grip);
      }
    }

    // eslint-disable-next-line
    if (barrelId === 9) barrelStyle = blendStyles.gray;
    // eslint-disable-next-line
    if (gripId === 9) gripStyle = blendStyles.gray;
    // eslint-disable-next-line
    if (barrelId === 14) barrelStyle = blendStyles.pink;
    // eslint-disable-next-line
    if (gripId === 14) gripStyle = blendStyles.pink;
    // eslint-disable-next-line
    if (gripId === 14) gripStyle = blendStyles.pink;
    // eslint-disable-next-line
    if (barrelId === 5) barrelStyle = blendStyles.washedWhite;
    // eslint-disable-next-line
    if (gripId === 5) gripStyle = blendStyles.washedWhite;
    // eslint-disable-next-line
    if (barrelId === 8) barrelStyle = blendStyles.flame;
    // eslint-disable-next-line
    if (gripId === 8) gripStyle = blendStyles.flame;

    // eslint-disable-next-line
    if (!barrelStyle) barrelStyle = blendStyles.default;
    // eslint-disable-next-line
    if (!gripStyle) gripStyle = blendStyles.default;

    // eslint-disable-next-line
  }, [barrelId, gripId, barrel, isGradient, ringId]);

  useEffect(() => setLogoColor(logo.hex), [logo, setLogoColor]);

  useEffect(() => {
    if (ring === undefined) {
      setIsGradient(true);
    } else {
      setIsGradient(false);
    }
  }, [ring, setIsGradient]);

  return (
    <div>
      <img src={gripColor} className={gripStyle} alt="Grip Color" />
      <img src={barrelColor} className={barrelStyle} alt="Barrel Color" />

      <img src={ring?.images?.ring} alt="Ring Color" />

      <div>
        <img src={logo?.images?.logo} alt="Logo Color" />
        <img
          src="/images/costume-layers/5-2.png"
          style={{ opacity: 0.47 }}
          alt="Logo Texture"
        />
      </div>
    </div>
  );
}
