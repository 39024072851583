import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { postApiData } from "services/getApiData";
import { useNavigate } from "react-router-dom";

const styles = {};

const pp_client_id = process.env.REACT_APP_PAYPAL_CLIENT_ID;
const ppInitialOptions = {
  "client-id": pp_client_id,
  currency: "USD",
  intent: "capture",
};
const ppStyleButton = {
  layout: "vertical",
  color: "black",
  lable: "pay",
  tagline: false,
};

const rush_cost = 25;

const getGrandTotal = (subtotal, shipping, isRush) => {
  const s = shipping || 0;
  const sub = parseFloat(subtotal) + parseFloat(s);
  const total = isRush !== 1 ? sub + rush_cost : sub;
  return total.toFixed(2);
};

export default function OrderOverviewPaypal({
  shippingOptions,
  selectedShipping,
  productionTime,
  buyer,
  cartData,
}) {
  const navigate = useNavigate();

  // Paypal stuff
  const createOrder = (datainfo, actions) => {
    const total = getGrandTotal(
      cartData.subtotal,
      shippingOptions[selectedShipping].total,
      productionTime
    );

    return actions.order.create({
      purchase_units: [
        {
          amount: {
            value: total,
            currency_code: "USD",
          },
        },
      ],
    });
  };

  const onApprove = async (datainfo, actions) => {
    try {
      return (
        actions.order
          .capture()
          /**
           * @param {Object} details ⬇
           * LINK req-resp/pp-onApprove.res.json
           */
          .then(async (details) => {
            const products = cartData.list.map((product) => {
              return {
                id: product.id,
                quantity: product.quantity,
                selected_options: product.product.selected_options,
              };
            });

            return {
              paypal_id: details.id,
              paypal_total: details.purchase_units[0].amount.value,
              buyer: buyer,
              productionTime: productionTime,
              shipping: shippingOptions[selectedShipping],
              products: products,
            };
          })
          .then(async (info) => {
            await postApiData("/order/", info);
            navigate(`/thanks?order_id=${info.paypal_id}`);
          })
      );
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className={styles.payBtnsContainer}>
      <PayPalScriptProvider options={ppInitialOptions}>
        <PayPalButtons
          createOrder={createOrder}
          onApprove={onApprove}
          style={ppStyleButton}
          forceReRender={[shippingOptions, selectedShipping, productionTime]}
        />
      </PayPalScriptProvider>
    </div>
  );
}
