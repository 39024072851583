import React from "react";
import styles from "./index.module.scss";

export default function Privacy() {
  return (
    <main id={styles.privacy}>
      <div className={`center ${styles.center}`}>
        <h1>POLITICA DE PRIVACIDAD</h1>

        <article>
          <h2>RECOPILACIÓN DE INFORMACIÓN</h2>
          <p>
            Al visitar sitios web, a veces se puede recopilar cierta información
            sobre usted y su computadora. Esta sección explica qué información
            recopila Victory VBats Co. y cómo se utiliza.
          </p>
          <span className={styles.subheader}>
            Recopilamos la siguiente información identificable sobre nuestros
            visitantes:
          </span>
          <ul>
            <li>usuario.nombre</li>
            <li>user.home-info.online.email</li>
            <li>user.home-info.postal</li>
            <li>user.home-info.telecom.telephone</li>
            <li>user.bdate (opcional)</li>
            <li>user.gender (opcional)</li>
            <li>thirdparty.name</li>
            <li>thirdparty.home-info.online.email</li>
            <li>thirdparty.home-info.postal</li>
            <li>thirdparty.home-info.telecom.telephone</li>
            <li>dinámico.clickstream</li>
            <li>dynamic.http</li>
            <li>texto de búsqueda dinámico</li>
            <li>dynamic.interactionrecord</li>
            <li>dynamic.miscdata</li>
          </ul>
          <span className={styles.subheader}>
            Recopilamos información identificable por los siguientes motivos:
          </span>
          <ul>
            <li>Finalización y apoyo de la actividad actual.</li>
            <li>El sitio web y la administración del sistema</li>
            <li>Investigación y desarrollo</li>
            <li>
              La recopilación de su información personal nos permite entregar
              sus productos de manera efectiva.
            </li>
          </ul>
          <p>
            Nuestro sitio web genera archivos de registro del servidor
            automáticamente. Estos archivos de registro se utilizan para generar
            información estadística e informes de errores para garantizar que el
            sitio web se ejecute con un mínimo de interrupción. No se utilizan
            activamente para identificar a los visitantes individuales.
          </p>
        </article>

        <article>
          <h2>RECEPTORES DE INFORMACION</h2>
          <p>
            <span className={styles.subheader}>
              ¿Quién tiene acceso a la información recogida?
            </span>
            Nosotros mismos y / o nuestras entidades actuando como nuestros
            agentes o entidades para las cuales estamos actuando como un agente.
          </p>
        </article>

        <article>
          <h2>RETENCION DE INFORMACION</h2>
          <p>
            <span className={styles.subheader}>
              ¿Por cuánto tiempo se conserva esta información?
            </span>
            No tenemos una política de retención para esta área del sitio web.
          </p>
        </article>
      </div>
    </main>
  );
}
