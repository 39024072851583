import { useState, useEffect } from "react";

import { useShipping } from "hooks/useShipping";
import { useCart } from "hooks/useCart";
import { postApiData } from "services/getApiData";

import BoxOption from "../../product/components/BoxOption";

import styles from "./orderOverview.module.scss";
import OrderOverviewProductList from "./components/OrderOverviewProductList";
import OrderOverviewPaypal from "./components/OrderOverviewPaypal";
import OrderOverviewShippingList from "./components/OrderOverviewShippingList";

export default function OrderOverview({
  setIsLoading,
  setIsShippingLoading,
  setGlobalError,
  isEditable,
  buyer,
  setIsCartEmpty,
}) {
  // Paypal => 1,
  const [paymentMethod, setPaymentMethod] = useState(null);
  const { data: cartData, loading: cartIsLoading, getCart } = useCart();
  const { shippingOptions, selectedShipping, setSelectedShipping } =
    useShipping(isEditable, buyer, cartData.quantity);
  const [productionTime, setProductionTime] = useState(1);
  const productionTimeHandler = (id) => setProductionTime(id);

  // 🙁 No puede renderiar por motivos de orden de stack
  // setGlobalError(error);
  // setIsShippingLoading(isLoading);

  const getConketa = async () => {
    setPaymentMethod(2);
    const products = cartData.list.map((product) => {
      return {
        id: product.id,
        quantity: product.quantity,
        selected_options: product.product.selected_options,
      };
    });

    const info = {
      conekta: true,
      productionTime: productionTime,
      buyer: buyer,
      shipping: shippingOptions[selectedShipping],
      products: products,
    };
    const resp = await postApiData("/order/", info);
    window.location.href = resp.redirect_uri;
  };

  useEffect(() => {
    setIsCartEmpty(!cartData.quantity);
    setIsLoading(cartIsLoading);
  }, [cartData, setIsCartEmpty, setIsLoading, cartIsLoading]);

  return (
    <div className={styles.overview}>
      <div>
        <h2>Order Overview</h2>
        {cartIsLoading ? (
          <div>Loading...</div>
        ) : (
          <>
            <OrderOverviewProductList
              getCart={getCart}
              cartData={cartData}
              setIsCartEmpty={setIsCartEmpty}
            />
            <article>
              <h2>Production Type</h2>
              <div className={`${styles.prodTime}`}>
                <BoxOption
                  name="standar"
                  id={1}
                  handler={productionTimeHandler}
                  current={productionTime}
                  note="3 to 5 weeks"
                />
                <BoxOption
                  name="rush"
                  id={2}
                  handler={productionTimeHandler}
                  current={productionTime}
                  note="1 to 2 week + 25DLLS"
                />
              </div>
            </article>
          </>
        )}
      </div>
      {shippingOptions && !isEditable && (
        <OrderOverviewShippingList
          shippingOptions={shippingOptions}
          selectedShipping={selectedShipping}
          setSelectedShipping={setSelectedShipping}
        />
      )}
      {shippingOptions && !isEditable && (
        <div className={styles.paymentMethods}>
          <div>
            <h2>Select payment method.</h2>
            <figure>
              <img
                className={paymentMethod === 1 ? styles.active : null}
                onClick={() => setPaymentMethod(1)}
                src="/images/pp-logo.jpg"
                alt="Paypal Logo"
              />
              <img
                className={paymentMethod === 2 ? styles.active : null}
                onClick={() => getConketa()}
                src="/images/conekta-logo.jpg"
                alt="Conekta Logo"
              />
            </figure>
          </div>
        </div>
      )}
      {paymentMethod === 1 && (
        <OrderOverviewPaypal
          shippingOptions={shippingOptions}
          selectedShipping={selectedShipping}
          productionTime={productionTime}
          buyer={buyer}
          cartData={cartData}
        />
      )}
    </div>
  );
}
