import { Link } from "react-router-dom";

import styles from "./styles/Footer.module.scss";

export default function Footer() {
  return (
    <footer className={styles.footer}>
      <div className={styles.yellowLine}></div>
      <div className={`center ${styles.center}`}>
        <figure>
          <Link to="/">
            <img src="/images/logotipo-vbats.png" alt="Official Logo" />
          </Link>
        </figure>
        <div className={styles.socialNetworks}>
          <a
            href="https://www.facebook.com/Victory-Bats-191025177649737/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src="/svg/facebook-logo.svg" alt="Facebook" />
          </a>
          <a
            href="https://twitter.com/VictoryBats"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src="/svg/twitter-logo.svg" alt="Twitter" />
          </a>
          <a
            href="https://www.instagram.com/vbatsco/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src="/svg/instagram-logo.svg" alt="Instagram" />
          </a>
        </div>
      </div>
      <div className={styles.subfooter}>
        <div className={`center ${styles.center}`}>
          <div className={styles.copy}>
            Victory Bats @ Gpe. Victoria Km 43 Mexicali, B.C. Mexico
          </div>
          <div className={styles.lawStatements}>
            <Link to="/terms-and-conditions">Terms and Conditions</Link>
            <Link to="/privacy-policy">Privacy Policy</Link>
          </div>
        </div>
      </div>
    </footer>
  );
}
