import Cart from "classes/Cart";
import styles from "./orderOverviewProductList.module.scss";

export default function OrderOverviewProductList({
  cartData,
  getCart,
  setIsCartEmpty,
}) {
  const removeItem = (e, index) => {
    e.preventDefault();
    new Cart().removeItem(index);
    if (cartData.quantity === 1) {
      setIsCartEmpty(true);
    } else {
      getCart();
    }
  };

  return (
    <div className={styles.orderOverviewProductList}>
      <div className={`${styles.titles}`}>
        <div className={styles.model}>&nbsp;</div>
        <div>Subtotal</div>
        <div>&nbsp;</div>
      </div>
      {cartData.list.map((item, index) => (
        <div key={index} className={styles.item}>
          <hgroup className={styles.model}>
            <h3>
              {`${item.quantity > 1 ? item.quantity + " x " : ""} ${
                item.title
              }`}
            </h3>
            <h4 className={styles.subtitle}>{item.model}</h4>
          </hgroup>
          <div className={styles.totals}>
            <span className={styles.value}>
              {`$${item.getTotal().total} ${item.getTotal().currency}`}
            </span>
          </div>
          <div className={styles.totals}>
            <button onClick={(evt) => removeItem(evt, index)}>
              <span className={styles.value}>x</span>
            </button>
          </div>
        </div>
      ))}
    </div>
  );
}
