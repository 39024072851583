import { useEffect, useState } from "react";

export const useCountries = () => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    fetch("/data/countries.json")
      .then((resp) => resp.json())
      .then((data) => {
        if (!data.errno) setData(data);
        else setError(data);
        setLoading(false);
      });
  }, []);

  return { data, loading, error };
};
