import { useState } from "react";
import styles from "./index.module.scss";

import Buyer from "classes/Buyer";
import { useRandom } from "hooks/useProducts";

import ProductList from "components/products/ProductList";
import BuyerInformation from "./components/BuyerInformation";
import OrderOverview from "./components/OrderOverview";
import BaseballPreloader from "components/BaseballPreloader";

export default function Checkout() {
  const [buyer, setBuyer] = useState(new Buyer());
  const [isEditable, setIsEditable] = useState(true);
  const { data: randomData, loading: loadingRandom } = useRandom();
  const [globalError, setGlobalError] = useState();
  const [isCartEmpty, setIsCartEmpty] = useState(true);
  const [loading, setLoading] = useState(true);

  const [isShippingLoading, setIsShippingLoading] = useState(false);

  return (
    <main className="center">
      <h1>CHECKOUT</h1>
      <section className={styles.cart}>
        {loading && <BaseballPreloader />}

        {!loading && isCartEmpty && (
          <div className={styles.noProducts}>
            You not have products in your cart.
          </div>
        )}

        <div
          className={`${styles.checkout} ${
            loading || isCartEmpty ? styles.hide : ""
          } `}
        >
          <BuyerInformation
            buyer={buyer}
            setBuyer={setBuyer}
            globalError={globalError}
            isEditable={isEditable}
            setIsEditable={setIsEditable}
            isShippingLoading={isShippingLoading}
          />
          <OrderOverview
            buyer={buyer}
            setIsLoading={setLoading}
            isEditable={isEditable}
            setIsShippingLoading={setIsShippingLoading}
            setGlobalError={setGlobalError}
            setIsCartEmpty={setIsCartEmpty}
          />
        </div>
      </section>
      <div className={`center `}>
        <h2>OTHER POPULAR PRODUCTS</h2>
        <ProductList loading={loadingRandom} data={randomData} />
      </div>
    </main>
  );
}
