import Cart from "classes/Cart";
import { useState, useEffect } from "react";
import { CheckoutItem } from "classes/CheckoutItem";
import { postApiData } from "../services/getApiData";

export function useCart() {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({
    quantity: 0,
    subtotal: 0,
    list: [],
  });

  const getCart = () => {
    const cart = new Cart();
    setLoading(true);
    postApiData("/cart/", cart.getCart()).then((data) => {
      let itemsList = [];

      if (!data.errno) {
        let subtotal = 0;

        data.products.items.forEach((item) => {
          const ci = new CheckoutItem(
            item.quantity,
            item.production_time,
            item.product
          );
          itemsList.push(ci);
          subtotal += ci.getTotal().total;
        });

        setData({
          quantity: data.products.count,
          subtotal: subtotal,
          list: itemsList,
        });
      } else {
        setError(data);
      }
      setLoading(false);
    });
  };

  useEffect(() => getCart(), []);

  return { data, loading, error, getCart };
}
