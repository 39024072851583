import { useState, useEffect } from "react";
import { getApiData } from "services/getApiData";

const _url = "/shipping/";
const _production = process.env.REACT_APP_PRODUCTION;

const fakeData = [
  { type: "N", currency: "USD", total: "9.99", name: "DOMESTIC EXPRESS" },
  {
    type: "G",
    currency: "USD",
    total: "19.99",
    name: "DOMESTIC ECONOMY SELECT",
  },
];

export const useShipping = (isEditable, buyerInfo, quantity) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [shippingOptions, setShippingOptions] = useState(null);
  const [selectedShipping, setSelectedShipping] = useState(0);

  const getShipping = async ({ city, country, zipcode, quantity }) => {
    setIsLoading(true);
    if (_production === "true") {
      setIsLoading(false);
      return fakeData;
    }

    const query = `?city=${city}&country=${country}&cp=${zipcode}&quantity=${quantity}`;
    const resp = await getApiData(_url + query);
    setIsLoading(false);
    return resp;
  };

  useEffect(() => {
    if (!isEditable && quantity) {
      setError(null);
      setIsLoading(true);
      setShippingOptions(null);
      try {
        getShipping({
          ...buyerInfo.getShippingInformation(),
          quantity,
        }).then((resp) => {
          if (resp.errno) {
            setError(
              "* At the moment we do not have shipments to that address, please check the data again."
            );
          } else {
            setError(null);
            setShippingOptions(resp);
          }
          setIsLoading(false);
        });
      } catch (err) {
        setIsLoading(false);
        console.error(err);
      }
    }
  }, [isEditable, buyerInfo, quantity]);

  return {
    isLoading,
    error,
    shippingOptions,
    getShipping,
    selectedShipping,
    setSelectedShipping,
  };
};
