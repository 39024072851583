import styles from "./orderOverviewShippingList.module.scss";

export default function OrderOverviewShippingList({
  shippingOptions,
  selectedShipping,
  setSelectedShipping,
}) {
  return (
    <div className={styles.shippingList}>
      <h2>Select a Shipping Method.</h2>
      <div>
        {shippingOptions.map((item, index) => (
          <div key={item.type}>
            <div
              className={`${selectedShipping === index ? styles.active : ""} ${
                styles.shippingOption
              }`}
              onClick={() => setSelectedShipping(index)}
            >
              <div>
                <span>{item.name.toUpperCase()}</span>
                <span className={styles.total}>
                  ${item.total} {item.currency}
                </span>
              </div>
              <div className={styles.check}>
                {selectedShipping === index && (
                  <span>
                    <img src="/svg/check.svg" alt="" />
                  </span>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
